export * from './authenticationController.service';
import { AuthenticationControllerService } from './authenticationController.service';
export * from './categoryController.service';
import { CategoryControllerService } from './categoryController.service';
export * from './discountController.service';
import { DiscountControllerService } from './discountController.service';
export * from './groupController.service';
import { GroupControllerService } from './groupController.service';
export * from './imageController.service';
import { ImageControllerService } from './imageController.service';
export * from './migrationController.service';
import { MigrationControllerService } from './migrationController.service';
export * from './productController.service';
import { ProductControllerService } from './productController.service';
export * from './refundController.service';
import { RefundControllerService } from './refundController.service';
export * from './stockCorrectionController.service';
import { StockCorrectionControllerService } from './stockCorrectionController.service';
export * from './transactionController.service';
import { TransactionControllerService } from './transactionController.service';
export * from './userController.service';
import { UserControllerService } from './userController.service';
export * from './userTypeController.service';
import { UserTypeControllerService } from './userTypeController.service';
export const APIS = [AuthenticationControllerService, CategoryControllerService, DiscountControllerService, GroupControllerService, ImageControllerService, MigrationControllerService, ProductControllerService, RefundControllerService, StockCorrectionControllerService, TransactionControllerService, UserControllerService, UserTypeControllerService];
