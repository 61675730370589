/**
 * Syntaxis-api API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SimpleUserReadDto } from './simpleUserReadDto';
import { DiscountReadDto } from './discountReadDto';


export interface GroupReadDto { 
    id?: number;
    name?: string;
    users?: Array<SimpleUserReadDto>;
    discounts?: Array<DiscountReadDto>;
}

