import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class Utils {
    public static isMobile() {
		return window && window.matchMedia('(max-width: 767px)').matches;
	}

    public static ngbDateToDate(ngbDate: { month, day, year }): Date {
		if(!ngbDate) {
			return null;
		}
		return new Date(`${ ngbDate.month }/${ ngbDate.day }/${ ngbDate.year }`);
	}

    public static dateToNgbDate(date: Date): NgbDate {
		if(!date) {
			return null;
		}
		date = new Date(date);
		return { month: date.getMonth() + 1, day: date.getDate(), year: date.getFullYear() } as NgbDate;
	}

	static scrollToTop(selector: string) {
		if(document) {
			const element = <HTMLElement>document.querySelector(selector);
			element.scrollTop = 0;
		}
	}

	public static genId(): string {
		let text = '';
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		for (let i = 0; i < 5; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	}
}
