import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ApiModule, Configuration, ConfigurationParameters } from '../../build/openapi';
import { authInterceptorProviders } from './shared/authentication-http-interceptor';

export function apiConfigFactory(): Configuration {
	const params: ConfigurationParameters = {
		basePath: environment.baseurl
	};
	return new Configuration(params);
}

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		ApiModule.forRoot(apiConfigFactory),
		BrowserModule,
		SharedModule,
		HttpClientModule,
		BrowserAnimationsModule,
		InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
		AppRoutingModule
	],
	providers: [
		authInterceptorProviders
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
