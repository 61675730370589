import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import {
	AuthenticationControllerService,
	AuthenticationReadDto,
	AuthenticationRefreshWriteDto,
	AuthenticationWriteDto,
	Configuration,
	UserReadDto
} from '../../../../build/openapi';

export const accessToken = 'accessToken';
export const refreshToken = 'refreshToken';
export const userToken = 'user';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private authenticated: boolean = false;

	constructor(
		private authenticationController: AuthenticationControllerService,
		private apiConfiguration: Configuration,
		private router: Router
	) {
		this.checkAuth();
	}

	public checkAuth(): boolean {
		this.authenticated = this.getToken() !== null && this.getRefreshToken() !== null;
		return this.authenticated;
	}

	public signin(credentials: {
		email: string,
		password: string
	}): Observable<AuthenticationReadDto> {
		return this.authenticationController.authenticate({
				email: credentials.email,
				password: credentials.password
			} as AuthenticationWriteDto
		).pipe(
			take(1),
			tap({
				next: (value: AuthenticationReadDto) => {
					this.authenticated = true;
					this.saveToken(value.accessToken);
					this.saveRefreshToken(value.refreshToken);
					this.saveUser(value.user);
				},
				error: () => {
					this.authenticated = false;
					this.clearStorage();
				}
			})
		);
	}

	public refreshToken(): Observable<AuthenticationRefreshWriteDto> {
		return this.authenticationController.refreshToken({
			refreshToken: this.getRefreshToken()
		} as AuthenticationRefreshWriteDto);
	}

	public signout(): void {
		this.authenticated = false;
		this.clearStorage();
		this.router.navigateByUrl('/sessions/signin');
	}

	public saveToken(token: string): void {
		window.sessionStorage.removeItem(accessToken);
		window.sessionStorage.setItem(accessToken, token);

		const user = this.getUser();
		if(user.id) {
			this.saveUser({ ...user, accessToken: token });
		}
	}

	public getToken(): string | null {
		return window.sessionStorage.getItem(accessToken);
	}

	public saveRefreshToken(token: string): void {
		window.sessionStorage.removeItem(refreshToken);
		window.sessionStorage.setItem(refreshToken, token);
	}

	public getRefreshToken(): string | null {
		return window.sessionStorage.getItem(refreshToken);
	}

	public saveUser(user: UserReadDto): void {
		window.sessionStorage.removeItem(userToken);
		window.sessionStorage.setItem(userToken, JSON.stringify(user));
	}

	public getUser(): any {
		const user = window.sessionStorage.getItem(userToken);
		if(user) {
			return JSON.parse(user);
		}

		return {};
	}

	private clearStorage(): void {
		window.sessionStorage.clear();
	}
}
