/**
 * Syntaxis-api API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocalTime } from './localTime';


export interface ProductWriteDto { 
    name?: string;
    categories?: Array<number>;
    price?: number;
    deposit?: number;
    visibleFromTime?: LocalTime;
    visibleFromAge?: number;
    discountType?: ProductWriteDto.DiscountTypeEnum;
}
export namespace ProductWriteDto {
    export type DiscountTypeEnum = 'NONE' | 'SINGLE';
    export const DiscountTypeEnum = {
        None: 'NONE' as DiscountTypeEnum,
        Single: 'SINGLE' as DiscountTypeEnum
    };
}


