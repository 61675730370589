/**
 * Syntaxis-api API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ImageReadDto } from './imageReadDto';


export interface SimpleProductReadDto { 
    id?: number;
    name?: string;
    price?: number;
    deposit?: number;
    discountType?: SimpleProductReadDto.DiscountTypeEnum;
    image?: ImageReadDto;
}
export namespace SimpleProductReadDto {
    export type DiscountTypeEnum = 'NONE' | 'SINGLE';
    export const DiscountTypeEnum = {
        None: 'NONE' as DiscountTypeEnum,
        Single: 'SINGLE' as DiscountTypeEnum
    };
}


