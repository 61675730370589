/**
 * Syntaxis-api API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserFinancialDataReadDto } from './userFinancialDataReadDto';
import { UserTypeReadDto } from './userTypeReadDto';


export interface UserReadDto { 
    id?: number;
    firstname?: string;
    lastname?: string;
    email?: string;
    roles?: Set<UserReadDto.RolesEnum>;
    userTypes?: Set<UserTypeReadDto>;
    financialData?: UserFinancialDataReadDto;
}
export namespace UserReadDto {
    export type RolesEnum = 'ADMIN' | 'USER' | 'TRANSACTION_ONLY_USER';
    export const RolesEnum = {
        Admin: 'ADMIN' as RolesEnum,
        User: 'USER' as RolesEnum,
        TransactionOnlyUser: 'TRANSACTION_ONLY_USER' as RolesEnum
    };
}


